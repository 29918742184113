import { useMount } from 'react-use';
import dayjs from 'dayjs';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import useQuery from '@/utils/hooks/useQuery';
import { TOKEN, LAST_REFRESH_TIME, NEED_REFRESH_TOKEN_DAY, REFRESH_TOKEN } from '@/utils/constants';
import CacheStorage from '@/utils/cacheStorage';
import { getTokenByRefreshTokenV2 } from '@/api/login';
import { removeRealToken, removeTokenInfo, setRealToken, setTokenInfo } from '@/utils/helpers';
import { goWebSite } from '@/utils/goWebSite';
import { isTourist } from '../utils';

export const getServerSideProps: GetServerSideProps = async ({ req, query }) => {
  const token = query?.token || req.headers?.token;
  return {
    props: {
      tokenApp: token || '',
    },
  };
};

const useAuth = (props?: { tokenApp: string }) => {
  const { tokenApp } = props || {};
  const { token: tokenUrl, ...restQuery } = useQuery() as Record<string, string>;
  const router = useRouter();
  const tourist = isTourist();
  useMount(() => {
    if (tourist) {
      // 临时存储 sign，手机号校验后清除
      removeRealToken();
    }
    if (tokenUrl) {
      CacheStorage.setItem(TOKEN, tokenUrl);
      const { pathname } = router;
      router.replace({ pathname, query: restQuery }, undefined, { shallow: true });
    }
    if (tokenApp) {
      CacheStorage.setItem(TOKEN, tokenApp);
    }
    const token = CacheStorage.getItem<string>(TOKEN);
    const lastRefreshTime = CacheStorage.getItem<string>(LAST_REFRESH_TIME);

    if (!tourist && !token) {
      if (window.location.href.includes(':3000/')) {
        setRealToken(
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhYzVmZmM0Ni0zYzYyLTQ3N2QtOWMzZS1mZTY0ZGRiMDFjZGQiLCJhdXRob3JpdGllcyI6WyJTVFVERU5UIl0sIm5hbWUiOiJ3a0NEa0RCOSIsImV4cCI6MTcyODQ1MzI2MTE4MSwicGhvbmUiOiIrODg2IDk5OTk4NTgwMiIsImVtYWlsIjoiZnNwYjEyNEAxNjMuY29tIiwiYWNjb3VudFN0YXR1cyI6Ik5PUk1BTCIsImRlcGFydG1lbnRzIjpbXSwiZGF0YSI6eyJzdHVkZW50SWQiOiJkMTFiZjhhYy1iYmI5LTRkMjctOGNlMi1kZTNhYzk5ZjdmYWUiLCJjb2RlIjoiQ1oyMEFPRDkiLCJhc3NvY2lhdGVkV2VDaGF0IjpmYWxzZSwic3R1ZGVudExldmVsIjozMCwiYXNzb2NpYXRlZFN0dWRlbnRzIjp0cnVlLCJwbGF0Zm9ybSI6IndlYiIsInN0YXR1cyI6IuacquS7mOi0uSJ9fQ.jS7dPnuBl-Ln9UwCWoawTqoMzpRtwkzlaKLOGkw6ltc',
        );
        return;
      }
      // 需要强制登录 获取学员信息
      goWebSite();
    } else if (token && (!lastRefreshTime || dayjs().add(NEED_REFRESH_TOKEN_DAY, 'day').unix() > +lastRefreshTime)) {
      getTokenByRefreshTokenV2({
        refreshToken: CacheStorage.getItem(REFRESH_TOKEN),
      })
        .then((res) => {
          const { content } = res || {};
          const { token: newToken, refreshToken: newRefreshToken } = content || {};
          if (newToken && newRefreshToken) {
            setTokenInfo(newToken, newRefreshToken);
          } else {
            removeTokenInfo(null, true);
          }
        })
        .catch((e) => {
          console.log(e, 'useAuth catch');
          removeTokenInfo(null, true);
        });
    }
  });
};

export default useAuth;
