import courseNameIcon from '@public/imgs/cancel-course/course-name.png';
import courseAddrIcon from '@public/imgs/cancel-course/course-addr.png';
import courseDateIcon from '@public/imgs/cancel-course/course-date.png';
import coursePrivateIcon from '@public/imgs/cancel-course/course-private.png';
import courseTimeRangeIcon from '@public/imgs/cancel-course/course-time-range.png';
import checked from '@public/imgs/cancel-course/checked.png';
import { IString } from './interface';

export { courseNameIcon, courseTimeRangeIcon, coursePrivateIcon, courseDateIcon, courseAddrIcon, checked };

export const STEP_ONE_INDEX = 0;
export const STEP_TWO_INDEX = 1;
export const STEP_THREE_INDEX = 2;
export const STEP_FOUR_INDEX = 3;
export const STEP_ONE = 1;
export const STEP_TWO = 2;
export const STEP_THREE = 3;

export const ClassType: IString = {
  PRIVATE: '私教课',
  GROUP: '班课',
};

// 取消约课埋点
export const CANCEL_COURSE_STEP1_CONTINUE = 'U_TrialCancel_Confirm_ContinueClick';
export const CANCEL_COURSE_STEP1_THINK_AGAIN = 'U_TrialCancel_Confirm_IThinkAgainClick';
export const CANCEL_COURSE_STEP2_CONTINUE = 'U_TrialCancel_TeacherRetention_ContinueClick';
export const CANCEL_COURSE_STEP2_THINK_AGAIN = 'U_TrialCancel_TeacherRetention_IThinkAgainClick';
export const CANCEL_COURSE_STEP3_CONFIRM = 'U_TrialCancel_Reason_ContinueClick';
export const CANCEL_COURSE_STEP3_THINK_AGAIN = 'U_TrialCancel_Reason_IThinkAgainClick';
export const CANCEL_COURSE_STEP4_BACK = 'U_TrialCancel_Result_ReturnHomeClick';
export const CANCEL_COURSE_STEP4_RESCHEDULE = 'U_TrialCancel_Result_RescheduleClick';
export const CANCEL_COURSE_STEP4_BOOK_CLICK = 'U_TrialCancel_Result_BookMyClassesClick';
