import qs from 'query-string';
import { useEffect, useState } from 'react';

const useQuery = () => {
  const search = globalThis.location?.search || '';
  const [query, setQuery] = useState(qs.parse(search));

  useEffect(() => {
    setQuery(qs.parse(search));
  }, [search]);

  return query;
};

export default useQuery;
