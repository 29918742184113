import { goWebSite } from '@/utils/goWebSite';
import Cookie from 'js-cookie';
// targetStep:老师列表=5; 老师详情=6;
// teacherId: 老师详情=6 时传string (可以不传递了)
export const GS2_TEACHER_LIST = '5';
export const GS2_TEACHER_DETAIL = '6';
export const GS2_STU_MOBILE = 'stu_mobile';
export const GS2_STU_PC = 'stu_PC';
export const goGs2Booking = (value: {
  teacherId?: string;
  source: 'stu_mobile' | 'stu_PC';
  targetStep: '5' | '6';
  trigger_name?: string;
  trigger_pvid?: string;
}) => {
  goWebSite(
    `experienceClassBooking?targetStep=${value.targetStep}&teacherId=${value?.teacherId || ''}&source=${value.source}&trigger_name=${value.trigger_name}&trigger_pvid=${value.trigger_pvid || Cookie.get('trigger_pvid')}`,
    { selectedLang: 'en' },
  );
};

/**
 * 获取学习报告的url，携带语言信息
 */
export const getStudyReportUrlWithLang = (url: string, lang: string) => {
  const urlArr = url.split('/trial-study-report');
  const spliceUrl = lang === 'zh' ? '/zh/trial-study-report' : '/en/trial-study-report';
  const jumpUrl = urlArr?.[0] + spliceUrl + urlArr?.[1];

  return jumpUrl;
};
