import client from './axios';
import { LessonListRes, LessonRes, OptionsResponse, SectionRes } from './types/ai';
import { ContentData } from './types/index';

export const hasAiLesson = () => {
  return client.post<ContentData<boolean>, ContentData<boolean>>(`/api/student/schedule/virtual/purchased`);
};

export const getTags = () => {
  return client.get<OptionsResponse, OptionsResponse>('/api/student/schedule/virtual/tags').then((res) => res.content);
};

export const getAiLessons = () => {
  return client
    .post<ContentData<LessonRes[]>, ContentData<LessonRes[]>>('/api/student/schedule/virtual/course/purchased')
    .then((res) => res.content);
};

export const getAiLectures = (courseId: string) => {
  return client.get<SectionRes, SectionRes>(`/api/student/schedule/virtual/course/course_sections/${courseId}`);
};

export const getCourseDetail = (courseId: string) => {
  return client.get<LessonRes, LessonRes>(`/api/student/schedule/virtual/course/${courseId}`);
};

// 获取购买的AI课课程
export const getAiCourseAndSectionList = () => {
  return client.get<LessonListRes, LessonListRes>(`/api/virtual_course/purchased`);
};
