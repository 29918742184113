import dayjs from 'dayjs';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import avatorDefault from '@public/imgs/home-chinese-en.png';
import { courseNameIcon, courseTimeRangeIcon, coursePrivateIcon, courseDateIcon, courseAddrIcon } from './constans';
import { IClassType, ICourseInfo } from '../interface';
import styles from './styles.module.scss';
import cls from 'classnames';
import { ClassType } from '../constants';
interface IProps {
  data?: Partial<ICourseInfo>;
}
export const renderFormatDate = (data: any, language: string) => {
  const { startDateTime, endDateTime } = data || {};
  if (!startDateTime) return null;
  const date = dayjs(startDateTime).format(language === 'zh' ? 'YYYY-MM-DD' : 'MMM DD, YYYY');
  const startTime = dayjs(startDateTime).format('h:mm A');
  const endTime = dayjs(endDateTime).format('h:mm A');
  return (
    <>
      {date}
      <span style={{ marginLeft: 12 }}>
        {startTime} - {endTime}
      </span>
    </>
  );
};
export const renderLocation = (data: any) => {
  const { studentTimeZone, timeZone } = data || {};
  return (
    <>
      ({studentTimeZone?.substring(1, studentTimeZone.indexOf(')'))}) {timeZone}
    </>
  );
};
const Step1 = ({ data }: IProps) => {
  const { t, i18n } = useTranslation(['trailCourse']);
  const { t: homeT } = useTranslation(['home']);
  const isZh = i18n.language === 'zh';
  const sectionName = isZh ? data?.sectionNameDisplay : data?.sectionNameDisplayEn;

  const InfoConfig = [
    {
      iconSrc: courseNameIcon,
      content: sectionName,
    },
    {
      iconSrc: courseDateIcon,
      content: renderFormatDate(data, i18n.language),
    },
    {
      iconSrc: courseAddrIcon,
      content: renderLocation(data),
    },
    {
      iconSrc: coursePrivateIcon,
      content: `${homeT(`${ClassType[data?.classType as IClassType]}`)}`,
    },
    {
      iconSrc: courseTimeRangeIcon,
      content: `${data?.duration}${isZh ? '' : ' '}${t('分钟')}`,
    },
  ];

  return (
    <div
      className={cls(
        styles.step1MainContent,
        'rounded-[8px] border-[1px] border-solid border-[#f0f0f0] mobile:mt-[24px] pc:mt-[32px] pc:w-[527px]',
      )}
    >
      <div className={cls(styles.teacherInfo, 'rounded-[8px_8px_0_0] mobile:p-[16px] pc:p-[24px]')}>
        <div className={styles.avatorBox}>
          <Image
            className={styles.avator}
            src={data?.teacherAvator || avatorDefault}
            width={60}
            height={60}
            alt="avator"
          />
        </div>
        <div className={cls(styles.profile, 'mobile:ml-[16px] pc:ml-[24px]')}>
          <span className={styles.name}>{data?.teacherName}</span>
          <span className={styles.desc}>{t('中文授课老师')}</span>
        </div>
      </div>
      <>
        {InfoConfig?.map((info, index) => {
          return (
            <div className={cls(styles.item, 'mobile: pc: p-[16px 24px] p-[16px]')} key={index}>
              <div className={styles.iconBox}>
                <Image width={16} height={16} className={styles.icon} src={info.iconSrc} alt="" />
              </div>
              <span className={cls(styles.courseDesc, 'mobile:ml-[16px] pc:ml-[24px]')}>{info.content}</span>
            </div>
          );
        })}
      </>
    </div>
  );
};

export default Step1;
